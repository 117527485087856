@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
*{
box-sizing: border-box;
font-family: 'Inter', sans-serif;

}

a{
text-decoration: none;
transition: 0.3s;
cursor: pointer;
}
button{
text-decoration: none;
transition: 0.3s;
outline: none;
cursor: pointer;
}
input{
outline: none;
}
.authwapper{
display: flex;
align-items: center;
height: 100vh;
overflow: hidden;
flex-wrap: wrap;
padding: 100px 0px;
}
.loginfrom{
width: 50%;
text-align: center;
}
.loginfrom span{
display: block;
}
.loginfrom form{
width: 430px;
margin: 0 auto;
}
.loginfrom form button{
width: 100%;
}
.loginfrom form .form-group{
position: relative;
padding-bottom: 20px;
}
.loginfrom form .form-group input{
border: 1px solid #CCC;
background: #FFF;
height: 50px;
padding: 10px 15px;
outline: none;
width: 100%;
padding-left: 43px;
appearance: none;
}
.companyinfo{
width: 50%;
height: 100%;
background-image: url(./assets/images/background.png);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
text-align: center;
padding: 150px 0px;
}
#navigation{
position: relative;
}
#navigation::before{
width: 50px;
height: 50px;
background: rgba(192, 3, 5, 0.10);
position: absolute;
right: 0px;
bottom: 0px;
content: "";
z-index: -1;
}
.fill-btn{
border: 1px solid #C00305;
background: #C00305;
color: #FFF;
text-align: center;
font-size: 16px;
font-weight: 500;
text-transform: uppercase;
padding: 15px 15px;
cursor: pointer;
}
.container{
width: 1440px;
max-width: 100%;
margin: 0 auto;
}
.authwapper .container{
display: flex;
align-items: center;
height: 100%;
overflow: hidden;
flex-wrap: wrap;
background: #fff;
border-radius: 0px;
box-shadow: 0px 0px 10px #ccc;
background-image: url(./assets/images/contback.png);
background-repeat: no-repeat;
background-size: 34%;
}
.authwapper  h1{
color: #000;
margin: 0px;
text-align: center;
font-size: 40px;
font-weight: 400;
margin-bottom: 50px;

}
.authwapper  h1 span{
color: #C00305;
font-weight: 700;
display: block;

}
form p{
font-size: 14px;
color: #333;
}
form p a{
color: #C00305;
font-weight: 500;
}
.checkbox input{
position: absolute; 
opacity: 0; 
width: auto !important;
}
.checkbox label{
position: relative;
cursor: pointer;
padding: 0;
color: #333;
font-size: 14px;
}
.checkbox label::before{
content: '';
margin-right: 10px;
display: inline-block;
vertical-align: text-top;
width: 14px;
height: 14px;
background: white;
border: 1px solid #C00305;
}
.checkbox input:checked + label:before {
background: #C00305;
}

.checkbox input:checked + label:after {
content: '';
position: absolute;
left: 4px;
top: 7px;
background: white;
width: 2px;
height: 2px;
box-shadow: 
2px 0 0 white,
4px 0 0 white,
4px -2px 0 white,
4px -4px 0 white,
4px -6px 0 white,
4px -8px 0 white;
transform: rotate(45deg);
}
.remember-me{
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 30px;
float: right;
}
.forgotpassword{
display: inline-block;
color: #C00305;
font-size: 14px;
}
.formIcon{
position: absolute;
top: 17px;
left: 15px;
}
.companyinfo{
position: relative;
}
.contactinfo{
position: absolute;
bottom: 100px;
left: 50%;
transform: translateX(-50%);
}
.companyinfo h2{
color: #FFF;
text-align: center;
font-size: 50px;
font-weight: 600;
margin: 0px;
margin-top: 30px;
margin-bottom: 25px;
}
.companyinfo p{
color: #FFF;
text-align: center;
font-size: 25px;
font-weight: 400;
margin: 0px;
}
.contactinfo a{
display: inline-block;
color: #fff;
font-size: 16px;
margin-bottom: 15px;
}
.contactinfo a img{
margin-right: 15px;
vertical-align: middle;
}
.fill-btn a{
color: #fff;
display: block;
height: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}
.wapper{
width: 100%;
height: 100vh;
overflow: hidden;
position: relative;
display: flex;
flex-wrap: wrap;
}
#navigation{
width: 60px;
height: 100%;
background-color: #fff;
transition: 0.3s;
background-image: url(./assets/images/background.png);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-top-right-radius: 30px;
border-bottom-right-radius: 30px;

}
#navigation.open{
width: 276px;
}
#navigation ul{
margin: 0px;
padding: 0px;
list-style: none;
}
#navigation ul li{
display: block;
position: relative;
}
#navigation ul li a{
color: #fff;
display: block;
text-align: left;
font-size: 14px;
font-weight: 500;
padding: 10px 22px;
}
#navigation ul li a span{
color: #fff;
}
#navigation.open ul li a{
padding: 10px 33px;
}
#navigation ul li a:hover{
background: #fff;
color: #C00305;
}
#navigation ul li a:hover span{
color: #C00305;
}
#pagewapper{
width: calc(100% - 60px);
height: 100%;
overflow: hidden;
transition: 0.3s;
}
#pagewapper.expend{
width: calc(100% - 276px);
}
nav{
padding: 35px 0px;
text-align: center;
position: relative;
padding-bottom: 45px;
height: 100%;

}
.logo{
width: 40px;
margin: 0 auto;
display: inline-block;
margin-bottom: 37px;
transition: 0.3s;
}
#navigation.open .logo{
width: 73px;
}
.logo img{
width: 100%;
}
#submenu{
background: rgb(0 0 0 / 70%);
display: none;
}
#submenu.active{
display: block;
}
#submenu li a{
font-size: 13px !important;
color: #fff;
padding: 10px 33px !important;
padding-left: 57px !important;
}
#submenu li a:hover{
background: #fff;
color: #C00305;
}
#navigation ul li a.active{
background: #fff;
color: #C00305;
}
#navigation ul li a.active span{
color: #C00305;
}
.header{
background: #fff;
padding: 17px 25px;
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
}
.header .navigation{
padding: 0px;
margin: 0px;
list-style: none;
background-image: none;
}
.header .navigation:before{
display: none;
}
.rightSec{
display: flex;
align-items: center;
}
.user{
display: flex;
align-items: center;
}
.user .usericon{
width: 43px;
height: 43px;
border-radius: 10px;
overflow: hidden;
}
.user .usericon img{
width: 100%;
height: 100%;
object-fit: cover;
}
.user .userdetails{
width: calc(100% - 43px);
padding-left: 15px;
}
.user .userdetails label{
font-size: 18px;
color: #333;
font-weight: 500;
margin-right: 15px;
}
.notification{
width: 43px;
height: 43px;
border-radius: 100%;
position: relative;
background: rgba(192, 3, 5, 0.10);
margin: 0px 22px;
}
.notification a{
display: block;
width: 100%;
height: 100%;
text-align: center;
padding: 8px 0px;

}
.notification a span{
width: 18px;
height: 18px;
color: #fff;
font-weight: 600;
background: #ED0507;
font-size: 10px;
display: block;
position: absolute;
border-radius: 100%;
top: 1px;
right: 5px;
text-align: center;
padding: 2px;
}
.rightSec form {
position: relative;
}
.rightSec form input{
border-radius: 40px;
border: 1px solid #CCC;
background: #FFF;
width: 250px;
height: 43px;
font-size: 14px;
color: #000;
padding: 10px 15px;
}
.rightSec form  button{
background: transparent;
border: none;
outline: none;
width: 43px;
height: 43px;
border-radius: 100%;
cursor: pointer;
position: absolute;
right: 0px;
transition: 0.3s;
}
.rightSec form button:hover{
background: rgba(192, 3, 5, 0.10);
}
header{
box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.10);
}
.mainbody{
width: 100%;
transition: 0.3s;
height:calc(100% - 77px);
position: relative;
overflow: auto;
background: rgba(192, 3, 5, 0.10);
padding: 20px;
}
.handel{
position: absolute;
right: -25px;
top: 50%;
transform: translateY(-50%);
padding: 13px 11px;
font-size: 26px;
outline: none;
border: 0px;
background: #ad140f;
color: #fff;
z-index: 1;
border-top-right-radius: 30px;
border-bottom-right-radius: 30px;
padding-left: 5px;
}
.mainbodyinner{
background:#fff;
width: 100%;
min-height: 100%;
position: relative;
padding: 20px;
}
#navigation ul li a{
display: flex;
}
#navigation ul li a i{
font-size: 17px;
margin-right: 10px;
vertical-align: bottom;
}
#navigation ul li a span{
width: 0px;
height: 0px;
white-space: nowrap;
transition: 0.3s;
opacity: 0;
visibility: hidden;
}
#navigation.open ul li a span{
display: inline-block;
opacity: 1;
width: auto;
height: auto;
visibility: visible;
}
.action{
position: relative;
}
.action ul{
position: absolute;
list-style: none;
padding: 0px;
margin: 0px;
background: #fff;
z-index: 1;
box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.10);
border-radius: 8px;
right: -5px;
top: 30px;
}
.action ul::before{
content: "";
width: 12px;
height: 12px;
position: absolute;
background: #fff;
box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.10);
transform: rotate(45deg);
right: 10px;
top: -6px;
border-top: 1px solid #ccc;
border-left: 1px solid #ccc;
z-index: -1;
}
.action ul li {
display: block;
}
.action ul li a{
display: flex;
align-items: center;
font-size: 14px;
color: #000;
width: 100%;
padding: 10px 20px;
transition: 0.3s;
}
.action ul li:first-child a{
border-top-left-radius: 8px;
border-top-right-radius:8px;
}
.action ul li:last-child a{
border-bottom-left-radius: 8px;
border-bottom-right-radius:8px;
}
.action ul li a:hover{
background: #ad140f;
color: #fff;
}
.action ul li a i{
font-size: 14px;
margin-right: 10px;
}
.user ul{
display: none;
}
.user ul.open{
display: block;
}
.row{
display: flex;
flex-wrap: wrap;
margin: 0px -15px;
}
.row .col-4{
width: 33.33%;
padding: 0px 15px;
}
.dashboard-item{
border-radius: 10px;
border: 1px solid rgba(0, 0, 0, 0.05);
background: #f5f5f5;
padding: 20px;
position: relative;
margin-bottom: 30px;
}
.dashboardTop{
display: flex;
align-items: center;
flex-wrap: wrap;

}
.dashboardTop .icon{
width: 40px;
height: 40px;
border-radius: 100%;
display: inline-flex;
align-items: 100%;
padding: 10px;
}
.dashboardTop h2{
color: #000;
font-size: 18px;
font-weight: 500;
margin: 0px;
margin-left: 15px;
}
.dashboard-mdl h3{
color: #000;
font-size: 25px;
font-weight: 600;
margin: 0px;
}
.dashboard-mdl{
margin-bottom: 20px;
display: flex;
justify-content: space-between;
align-items: center;
}
.dashboard-item p{
color: #333;
font-size: 14px;
font-weight: 400;
margin: 0px;
}
.graph {
display: flex;
align-items: flex-end;
}
.graph span{
width: 10px;
height: 30px;
background: rgba(217, 217, 217, 0.50);
display: inline-block;
margin-right: 10px;
border-radius: 3px;
}
.graph span:nth-child(2){
height: 40px;
}
.graph span:nth-child(3){
height: 50px;
background-color: #ad140f;
}
.graph span:last-child{
height: 30px;
}
.bck-red{
background: rgba(237, 5, 7, 0.20);
}
.bck-grn{
background: rgba(36, 188, 11, 0.30);
}
.bck-ylw{
background: rgba(231, 181, 5, 0.30);
}
.col-8{
padding: 0px 15px;
width: 66.666%;
}
.dashboard-card{
position: relative;
background: #f5f5f5;
padding: 24px;
border-radius: 10px;
border: 1px solid rgba(0, 0, 0, 0.05);
}
.dashboard-card h3{
color: #000;
font-size: 18px;
font-weight: 500;
margin: 0px;
margin-bottom: 12px;
}
.dashboard-card p{
color: #333;
font-size: 12px;
font-weight: 400;
margin: 0px;
margin-bottom: 20px;
}
.dashboard-card img{
width: 100%;
}
.filter form{
display: flex;
align-items: center;
width: 100%;
flex-wrap: wrap;
justify-content: space-between;
}
.filter form .form-group{
position: relative;
margin: 0px;
margin-bottom: 20px;
width: 30.33%;
}
.filter form .form-group input{
width: 100%;
height: 40px;
border: 1px solid #CCC;
background: #FFF;
padding: 10px 15px;
font-size: 14px;
}
.filter form .form-group label{
color: #000;
font-size: 14px;
font-weight: 400;
display: block;
margin-bottom: 8px;
}
.filter form .form-group label sub{
color: #C00305;
vertical-align: top;
}
.filter form button{
height: 40px;
border: 1px solid #ad140f;
background: #ad140f;
color: #fff;
font-size: 14px;
cursor: pointer;
transition: 0.3s;
padding: 0px 30px;
margin-top: 2px;
}
.filter form button:hover{
border: 1px solid #ad140f;
background: #fff;
color: #ad140f;
}
.search .filter{
border-bottom: 1px solid #ccc;
margin-bottom: 20px;
}
.customTab .react-tabs ul{
display: flex;
align-items: center;
flex-wrap: wrap;
width: 100%;
padding: 0px;
margin: 0px;
list-style: none;
border-bottom: 1px solid #D9D9D9;
margin-bottom: 15px;
}
.customTab .react-tabs ul li {
color: #C00305;
font-size: 14px;
font-weight: 400;
display: inline-block;
padding: 8px 15px;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
background: rgba(192, 3, 5, 0.10);
margin-right: 10px;
cursor: pointer;
transition: 0.3s;
outline: none;
}
.customTab .react-tabs ul li:hover{
background: #C00305;
color: #fff;
}
.customTab .react-tabs ul li.react-tabs__tab--selected{
background: #C00305;
color: #fff;
}
.tableResponsive{
position: relative;
border-radius:10px;
background: #FFF;
overflow: auto;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
margin-bottom: 30px;
overflow-x: auto;
}
.tableResponsive table{
width: 100%;
border-spacing: 0px;
}
.tableResponsive table tr th{
font-size: 14px;
color: #fff;
font-weight: 500;
background-color: #C00305;
text-align: left;
padding: 15px 33px;

}
.tableResponsive table tr td{
font-size: 14px;
color: #333;
text-align: left;
padding: 7px 33px;
}
.tableResponsive table tr td select{
border-radius: 2px;
border: 1px solid rgba(192, 3, 5, 0.50);
background: #FFF;
height: 30px;
padding: 5px 15px;
width: 100%;
outline: none;
font-size: 13px;
}
.tableResponsive table tr td input{
border-radius: 2px;
border: 1px solid rgba(192, 3, 5, 0.50);
background: #FFF;
height: 30px;
padding: 5px 15px;
width: 100%;
outline: none;
font-size: 13px;
}
.tableResponsive table tbody tr:nth-child(even){
background: rgba(192, 3, 5, 0.10);
}
.tablenav p{
color: #000;
font-size: 14px;
font-weight: 400;
margin: 0px;
margin-bottom: 20px;
}
.fillBtn{
color: #FFF;
text-align: center;
font-size: 14px;
font-weight: 500;
border-radius: 4px;
background: #C00305;
padding: 8px 22px;
border: 1px solid #C00305;
transition: 0.3s;
}
.fillBtn:hover{
background: #fff;
color: #C00305;
}
.tableResponsive table tr td a{
display: inline-block;
padding: 0px 10px;
}
.tableResponsive table tr td:last-child a{
padding-left: 0px;
}
.inpflid{
display: flex;
align-items: center;
}
.inpflid a{
margin-left: 10px;
}
.budget{
display: inline-block;
color: #FFF;
font-size: 13px;
font-weight: 500;
border-radius: 2px;
border: 1px solid #C00305;
background: #C00305;
padding: 5px 12px;
}
.intractiontype{
display: flex;
align-items: center;
position: absolute;
right: 0px;
top: -7px;

}
.intractiontype label{
color: #000;
font-size: 13px;
font-weight: 500;
display: inline-block;
margin-right: 10px;
margin-bottom: 0px;
}
.intractiontype select{
border: 1px solid #CCC;
width: 200px;
height: 30px;
outline: none;
background: #FFF;
padding: 10px 15px;
margin-right: 10px;
}
.intractiontype .fillBtn{
border-radius: 0px;
height: 30px;
padding: 2px 22px;
}
.customTab{
position: relative;
}
.intractiontype form{
display: flex;
}
.pageTop{
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
margin-bottom: 25px;
}
.pageTop h2{
color: #000;
font-size: 18px;
font-weight: 400;
margin: 0px;
}
.pageTop h2 i{
font-size: 20px;
margin-right: 10px;
color: #ad140f;
}
.pageTop form{
display: flex;
align-items: center;
margin-right: 16px;
}
.right-action{
display: flex;
align-items: center;
}
.right-action form input{
border: 1px solid #CCC;
height: 40px;
width: 150px;
background: #FFF;
padding: 10px 15px;
color: #000;
outline: none;
margin-right: 10px;
}
.right-action form select{
border: 1px solid #CCC;
height: 40px;
width: 150px;
background: #FFF;
padding: 10px 15px;
color: #000;
outline: none;
margin-right: 10px;
}
.right-action form  button{
outline: none;
height: 40px;
width: 40px;
cursor: pointer;
background: #c00305;
border: 0px;
}
.fadebtn{
color: #C00305;
font-size: 14px;
font-weight: 500;
border-radius: 8px;
background: rgba(192, 3, 5, 0.20);
padding: 10px 15px;
transition: 0.3s;
position: relative;
}
.fadebtn:hover{
background: #C00305;
color: #fff;
}
.fillbtn{
color: #fff;
font-size: 14px;
font-weight: 500;
border-radius: 8px;
background: #C00305;
padding: 10px 15px;
margin-left: 10px;
transition: 0.3s;
}
.fillbtn:hover{
color: #C00305;
background: rgba(192, 3, 5, 0.20);
}
.contactList thead tr th:first-child{

padding: 7px 33px;
padding-right: 0px;
}
.contactList thead tr th:nth-child(2){
padding-left: 0px;
}
.contactList tbody tr td:first-child{

padding: 7px 33px;
padding-right: 0px;
}
.contactList tbody tr td:nth-child(2){
padding-left: 0px;
}
.inactive{
color: #C00305 !important;
}
.sucess{
color: #24BC0B !important;
}
.dashboRd .tableResponsive{
margin-top: 20px;
}

.tglBtn input[type=checkbox]{
height: 0;
width: 0;
visibility: hidden;
display: none;
}

.tglBtn label {
cursor: pointer;
text-indent: -9999px;
width: 30px;
height: 16px;
background: grey;
display: block;
border-radius: 100px;
position: relative;
}

.tglBtn label:after {
content: '';
position: absolute;
top: 3px;
left: 3px;
width: 10px;
height: 10px;
background: #fff;
border-radius: 100%;
transition: 0.2s;
}

.tglBtn input:checked + label {
background: #24BC0B;
}

.tglBtn input:checked + label:after {
left: calc(100% - 3px);
transform: translateX(-100%);
}

.tglBtn label:active:after {
width: 10px;
}

.toggleItem{
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 20px;
}
.forlbl{
color: #000;
font-size: 14px;
font-weight: 400;
}
.exploresec{
text-align: center;
}
.exploresec img{
width: auto;
}
.tabsec .react-tabs {
display: flex;
flex-wrap: wrap;
}

.tabsec .react-tabs .tab-left{
width: 30.33%;
border-right: 1px solid #ccc;
padding-right: 20px;
}
.tabsec .react-tabs .tab-right{
width: 69.666%;
padding-left: 20px;
}
.tabsec .react-tabs .tab-left .react-tabs__tab-list{
padding: 0px;
margin: 0px;
list-style: none;
}
.tabsec .react-tabs .tab-left .react-tabs__tab-list li{
color: #000;
font-size: 15px;
font-weight: 400;
padding: 10px 25px;
cursor: pointer;
transition: 0.3s;
outline: none;
}
.tabsec .react-tabs .tab-left .react-tabs__tab-list li:hover{
background: #C00305;
color: #fff;
}
.tabsec .react-tabs .tab-left .react-tabs__tab-list li.react-tabs__tab--selected{
background: #C00305;
color: #fff;
}
.tabsec .react-tabs .tab-right .accordion__item{
border-radius: 10px;
overflow: hidden;
background: #fff;
box-shadow: 0px 3px 10px rgb(204 204 204 / 57%);
margin-bottom: 20px;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__heading .accordion__button{
width: 100%;
background: #C00305;
display: flex;
align-items: center;
justify-content: space-between;
padding: 8px 20px;
cursor: pointer;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__heading .accordion__button h2{
color: #FFF;
font-size: 15px;
font-weight: 500;
margin: 0px;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__heading .accordion__button a{
color: #C00305;
font-size: 13px;
font-weight: 500;
display: inline-block;
border-radius: 5px;
background: #FFF;
background: #fff;
padding: 8px 12px;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel{
padding: 20px 25px;
}
.accordion__panel table{
width: 100%;
border-spacing: 0px;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel th{
color: #000;
font-size: 14px;
font-weight: 600;
text-align: left;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel td a{
color: #C00305;
font-size: 13px;
font-weight: 400;
display: inline-block;
padding: 8px 16px;
border-radius: 3px;
border: 1px solid #C00305;
background: #FFF;
transition: 0.3s;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel td a:hover{
background: #C00305;
color: #fff;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel td select{
width: 235px;
max-width: 100%;
border-radius: 3px;
border: 1px solid #CCC;
background: #FFF;
height: 40px;
padding: 10px 15px;
font-size: 13px;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel td:last-child{
text-align: right;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel tr th:last-child{
text-align: right;
}
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel table tr td,
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel table tr th{
border-bottom: 1px solid #ccc;
padding: 12px 0px ;
}
/* .tabsec .react-tabs .tab-right .accordion__item .accordion__panel table tr:last-child td,
.tabsec .react-tabs .tab-right .accordion__item .accordion__panel table tr:last-child th{
border-bottom: 0px;
} */
.accounttList table tr td.reject{
color: #C00305 !important;

}
.accounttList table tr td:last-child{
font-weight: 600;
}
.accounttList table tr td:last-child span{
display: block;
font-size: 12px;
font-weight: normal;
}
.accounttList table tr td.active span{
color: #24bc0b;
border: 1px solid #24bc0b;
border-radius: 2px;
padding: 2px 10px;
display: inline-block;
}

.accounttList table tr td.inactive span{
color: #C00305;
border: 1px solid #C00305;
border-radius: 2px;
padding: 2px 10px;
display: inline-block;
}
.accounttList table tr{
transition: 0.3s;
}
.accounttList table tr:hover{
background: #ad140f !important;
cursor: pointer;
}
.accounttList table tr:hover td{
color: #fff;
}
.accounttList table tr:hover td span{
color: #fff !important;
border-color: #fff !important;
}
.accounttList table tr:hover td:last-child{
color: #fff !important;
}
.accounttList .pageTop{
flex-wrap: wrap;
}
.accounttList .pageTop .right-action{
width: 100%;
margin-top: 15px;
justify-content: space-between;
}
.checkBoxGroup{
display: flex;
align-items: center;

}
.checkBoxGroup .checkBoxGroupItem{
margin-right: 15px;
}
.checkBoxGroup .checkBoxGroupItem label{
color: #000;
font-size: 13px;
font-weight: 400;
margin: 0px;
}

.checkBoxGroup .checkBoxGroupItem [type="radio"]:checked,
.checkBoxGroup .checkBoxGroupItem [type="radio"]:not(:checked) {
position: absolute;
left: -9999px;
}
.checkBoxGroup .checkBoxGroupItem [type="radio"]:checked + label,
.checkBoxGroup .checkBoxGroupItem [type="radio"]:not(:checked) + label
{
position: relative;
padding-left: 28px;
cursor: pointer;
line-height: 20px;
display: inline-block;
color: #666;
}
.checkBoxGroup .checkBoxGroupItem [type="radio"]:checked + label:before,
.checkBoxGroup .checkBoxGroupItem [type="radio"]:not(:checked) + label:before {
content: '';
position: absolute;
left: 0;
top: 0;
width: 18px;
height: 18px;
border: 1px solid #ddd;
border-radius: 100%;
background: #fff;
}
.checkBoxGroup .checkBoxGroupItem [type="radio"]:checked + label:after,
.checkBoxGroup .checkBoxGroupItem [type="radio"]:not(:checked) + label:after {
content: '';
width: 12px;
height: 12px;
background: #ad140f;
position: absolute;
top: 4px;
left: 4px;
border-radius: 100%;
-webkit-transition: all 0.2s ease;
transition: all 0.2s ease;
}
.checkBoxGroup .checkBoxGroupItem [type="radio"]:not(:checked) + label:after {
opacity: 0;
-webkit-transform: scale(0);
transform: scale(0);
}
.checkBoxGroup .checkBoxGroupItem [type="radio"]:checked + label:after {
opacity: 1;
-webkit-transform: scale(1);
transform: scale(1);
}
.overviewTopflex{
display: flex;
align-items: flex-start;
justify-content: space-between;
}
.overviewTopflex h3{
color: #000;
font-size: 18px;
font-weight: 500;
margin: 0px;
}
.overviewTopflex h3 span{
color: #E78C05;
font-size: 12px;
font-weight: 500;
display: inline-block;
border-radius: 5px;
border: 1px solid #E78C05;
padding: 2px 9px;
margin-left: 10px;

}
.overviewTopflex h3 small{
display: block;
color: #C00305;
font-size: 12px;
}
.editBtn{
width: 30px;
height: 30px;
display: inline-block;
background: #C00305;
border-radius: 5px;
text-align: center;
color: #fff;
border: 1px solid #C00305;
padding: 4px 5px;
transition: 0.3s;
}
.editBtn:hover{
color: #C00305;
background: #fff;
}
.overViewDetails{
display: flex;
flex-wrap: wrap;
margin-top: 25px;
border-bottom: 1px solid #ccc;
padding-bottom: 10px;
margin-bottom: 20px;
}
.overViewDetailsItem{
width: 25%;
}
.overViewDetailsItem label{
color: #000;
font-size: 15px;
font-weight: 500;
display: block;
margin-bottom: 5px;
}
.overViewDetailsItem p{
color: #333;
font-size: 14px;
font-weight: 400;
margin: 0px;
margin-bottom: 10px;
}
.overViewDetailsItem p a{
color: #333;
transition: 0.3s;
}
.overViewDetailsItem p i{
margin-right: 10px;
color: #C00305;
}
.overViewDetailsItem p a:hover{
color: #C00305;
}
.flxp{
display: flex;
}
.flxp span{
display: inline-block;
width: 200px;
max-width: 100%;
}
.paymentDetails label{
color: #000;
font-size: 15px;
font-weight: 500;
display: block;
margin-bottom: 6px;
}
.paymentDetails h4{
display: flex;
align-items: center;
margin: 0px;
justify-content: space-between;
}
.paymentDetails span{
color: #333;
font-size: 14px;
font-weight: 400;
}
.paymentDetails span b{
font-weight: 600;
margin-left: 20px;
}
.notifysec{
background: #C00305;
padding: 4px 15px;
text-align: right;
margin-top: 20px;
margin-bottom: 15px;
}
.notifysec a{
background: #fff;
color: #C00305;
font-size: 12px;
font-weight: 400;
display: inline-block;
padding: 4px 10px;
border-radius: 3px;
margin-right: 15px;
}
.notifysec{
color: #fff;
font-size: 14px;
}
.overviewsec .card{
background: #fff;
border-radius: 10px;
box-shadow: 0px 5px 10px #ccc;
overflow: hidden;
}
.card-header{
color: #FFF;
font-size: 16px;
font-weight: 600;
width: 100%;
padding: 16px 25px;
background: #C00305;
}
.col-6{
width: 50%;
padding: 0px 15px;
}
.card-body{
padding: 20px;
}
.card-body ul{
margin: 0px;
padding: 0px;
list-style: none;
width: 100%;
border-bottom: 0px !important;
}
.card-body li label{
margin: 0px;
}
.card-body li{
width: 100%;
background: #fff !important;
display: flex !important;
color: #000 !important;
align-items: center;
justify-content: space-between;
padding: 10px 0px !important;
border-bottom: 1px solid #ccc !important;
}
.no-record{
display: flex;
align-items: center;
justify-content: center;
}
.no-record p{
margin: 0px;
color: #888;
display: flex;
align-items: center;
}
.no-record p img{
margin-right: 10px;
}
.card{
margin-bottom: 20px;
}
.col-12{
padding: 0px 15px;
width: 100%;
}
.card .tableResponsive{
box-shadow: none;
margin: 0px;
border-radius: 0px;
}
.card .tableResponsive table tr th{
text-align: left;
color: #000;
background: #fff;
}
.card .tableResponsive table tr td{
padding: 12px 33px;
}
.card .tableResponsive table tbody tr:nth-child(odd){
background: rgba(192, 3, 5, 0.10);
}
.card .tableResponsive table tbody tr:nth-child(even){
background: #fff;
}


.accountTabs{
position: relative;
}
.accountintractionList .pageTop{
position: absolute;
top: -40px;
width: auto;
right: 0px;;
}
.mgn10{
margin-right: 10px;
}
.dropemnu{
position: absolute;
background: #fff;
z-index: 1;
filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.17));
border-radius: 10px;
width: 182px;
top: 38px;
right: 0;
transition: 0.3s;
visibility: hidden;
pointer-events: none;
transform: translate(0px ,50px);
opacity: 0;
}
.dropemnu::before{
width: 12px;
height: 12px;
background: #fff;
position: absolute;
transform: rotate(45deg);
content: "";
border-left: 1px solid rgb(204 204 204 / 35%);
border-top: 1px solid rgb(204 204 204 / 35%);
right: 16px;
top: -8px;

}
.dropemnu ul{
margin: 0px !important;
border-bottom: 0px !important;
}
.dropemnu ul li{
width: 100%;
margin: 0px !important;
border-radius: 0px !important;
background: #fff !important;
font-size: 14px;
transition: 0.3s;
position: relative !important;
}
.dropemnu ul li:hover{
background: #C00305 !important;
color: #fff !important;
}
.dropemnu ul li:hover a{
color: #fff !important;
}
.dropemnu ul li:first-child{
border-top-left-radius: 10px !important;
border-top-right-radius: 10px !important;
}
.dropemnu ul li:last-child{
border-bottom-left-radius: 10px !important;
border-bottom-right-radius: 10px !important;
}
.dropemnu ul li a{
width: 100%;
color: #000;

}
.fadebtn:hover .dropemnu{
opacity: 1;
pointer-events: all;
visibility: visible;
transform: translate(0px ,0px);

}
.accountServiceTab .react-tabs__tab-list{
margin: 0px !important;
border: 0px !important;
margin-left: 20px !important;
}
.accountServiceTab .tabPan{
border: 1px solid #ccc;
border-radius: 10px;
padding: 20px;
}
.addBtn{
border: 1px solid #fff;
transition: 0.3s;
color: #C00305;
font-size: 14px;
font-weight: 500;
display: inline-block;
padding: 10px 10px;
border-radius: 10px;
font-weight: 600;
}
.addBtn:hover{
border: 1px solid #C00305;
color: #C00305;
}
.addBtn img{
margin-right: 8px;
}
td.reject{
color: #C00305 !important;
cursor: pointer;
}
td.success{
color: #08ca48 !important;
cursor: pointer;
}
td.inactive{
color: #C00305;
cursor: pointer;
}
td.active{
color: #08ca48 !important;
cursor: pointer;
}
.accountDocument .pageTop{
position: absolute;
top: -40px;
width: auto;
right: 0px;;
}
.accountHistory .pageTop{
position: absolute;
top: -40px;
width: auto;
right: 0px;;
}
.downloadbtn{    
width: 40px;
height: 40px;
border-radius: 100%;
background: rgba(36, 188, 11, 0.2);
color: #24BC0B;
text-align: center;
padding: 10px;
margin-left: 10px;
}
.downloadbtn:hover{
background: #24BC0B;
color: #fff;
}
.invoiceBtn{
color: #1031E1;
margin-left: 0px;
}
.tableResponsive .accordion .accordion__button h2{
color: #C00305;
font-size: 15px;
font-weight: 500;
margin: 0px;
}
.tableResponsive .accordion  .accordion__item{
border: 1px solid #ccc;
border-radius: 10px;
padding: 15px;
margin-bottom: 20px;
}

.caseAccount thead tr th:first-child{
padding: 7px 33px;
padding-right: 0px;
width: 24px;
}
.caseAccount thead tr th:nth-child(2){
padding-left: 0px;
}
.caseAccount tbody tr td:first-child{
padding: 7px 33px;
padding-left: 20px;
}
.caseAccount tbody tr td:nth-child(2){
padding-left: 0px;
}
.caseAccount table table tbody tr:nth-child(odd){
background: rgba(192, 3, 5, 0.10);
}
.tableResponsive table table tbody tr:nth-child(even) {
background: #fff;
}
.tableResponsive table table{
margin-top: 15px;
}
.exlBtn{    display: inline-block;
width: 40px;
height: 40px;
border: 2px solid #24bc0b;
text-align: center;
padding: 6px 0px;
margin-right: 10px;}
.pdfBtn{    display: inline-block;
width: 40px;
height: 40px;
border: 2px solid #ed0507;
text-align: center;
padding: 6px 0px;
margin-right: 10px;
}
.accountProfile .form-group{
    padding-bottom: 20px;
    position: relative;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
}
.accountProfile .form-group label{
color: #000;
font-size: 14px;
font-weight: 400;
display: block;
margin-bottom: 6px;
}
.accountProfile .form-group input{
    width: 100%;
    border: 1px solid #CCC;
    height: 40px;
    width: 100%;
    padding: 10px 15px;
    background: #fff;
}
.accountProfile .form-group select{
    width: 100%;
    border: 1px solid #CCC;
    height: 40px;
    width: 100%;
    padding: 10px 15px;
    background: #fff;
 
}
select{
    outline: none !important;
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    background-image: url(./assets/images/down.png) !important;
    background-repeat: no-repeat !important;
    background-position: 98% !important;
}
.accountProfile .form-group input[readonly]{
    background: rgba(217, 217, 217, 0.50);
}
.accountProfile .form-group span{
    color: #ed0507;
}
.formsec{
    display: flex;
    margin: 0px -15px;
    flex-wrap: wrap;
}
::selection{
    background: #ed0507;  
}
.formsec h2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.formsec h2 .checkbox label::before{
    width: 20px;
    height: 20px;
}
.formsec h2 .checkbox input:checked + label:after{
    left: 6px;
    top: 10px;
}
.accountProfile .card{
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 5px 10px #ccc;
    overflow: hidden;
}

.accountProfile .card .card-header{
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    padding: 16px 25px;
    background: #C00305;
}
.sbmtBtn{
    cursor: pointer;
    outline: none;
    background: #C00305;
    color: #FFF;
    font-size: 13px;
    font-weight: 400;
}
.accountProfile .searchFrm{
    position: relative;
    padding-bottom: 20px;
}
.accountProfile .searchFrm input{
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #ccc;
    height: 40px;
    font-size: 14px;
    color: #000;
    padding: 10px 15px;
    padding-left: 0px;
}
.accountProfile .searchFrm button{
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 4px;
    background: #C00305;
    border: 1px solid #C00305;
    cursor: pointer;
    padding: 5px 13px;
}
.accountProfile .searchFrm button:hover{
    color: #C00305;
    background: #fff;
}
.backBtn{
    border-radius: 4px;
    background: #C00305;
    border: 1px solid #C00305;
    cursor: pointer;
    padding: 5px 13px;
    color: #fff;
}
.backBtn i{
    font-size: 16px;
}
.backBtn:hover{
    color: #C00305;
    background: #fff; 
}
.btnGroup button{
    border: 0px;
    margin-right: 10px;
}
button[disabled]{
    color: #CCC !important;
    background: rgba(192, 3, 5, 0.10) !important;
    pointer-events: none;
}
.accountbankdetails thead tr th:first-child{
    padding: 7px 33px;
    padding-right: 0px;
    width: 24px;
    }
.accountbankdetails thead tr th:nth-child(2){
    padding-left: 0px;
    }
.accountbankdetails tbody tr td:first-child{
    padding: 7px 33px;
  
    }
.accountbankdetails tbody tr td:nth-child(2){
    padding-left: 0px;
    }
    .actionTable select{
        width: 250px;
        border: 1px solid #ccc;
        height: 40px;
        font-size: 14px;
        color: #000;
        padding: 10px 15px;
    }
    .actionTable{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
      .addcaseaccount form .form-group,
      .addBranch form .form-group,
      .accountbankdetails form .form-group{
        position: relative;
        margin: 0px;
        padding-bottom: 20px;
        }
        .addcaseaccount form .form-group input,
        .addcaseaccount form .form-group select,
        .accountbankdetails form .form-group input,
        .accountbankdetails form .form-group select,
        .addBranch form .form-group input,
        .addBranch form .form-group select{
        width: 100%;
        height: 40px;
        border: 1px solid #CCC;
        background: #FFF;
        padding: 10px 15px;
        font-size: 14px;
        }
        .addcaseaccount form .form-group label,
        .addBranch form .form-group label,
        .accountbankdetails form .form-group label{
        color: #000;
        font-size: 14px;
        font-weight: 400;
        display: block;
        margin-bottom: 8px;
        }
        .addcaseaccount form .form-group label sub,
        .addBranch form .form-group label sub,
        .accountbankdetails form .form-group label sub{
        color: #C00305;
        vertical-align: top;
        }

    .addcaseaccount form .btnGroup button:first-child,
    .addBranch form .btnGroup button:first-child,
    .accountbankdetails form .btnGroup button:first-child{
            margin-left: 0px;
        }
   
  .disabledselect{
    background: rgb(204 204 204 / 46%) !important;
  }
  .ReactModal__Overlay{
    background-color: rgb(0 0 0 / 40%) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .ReactModal__Content{
    inset: 0% !important;
    padding: 0px !important;
    overflow: hidden;
    position: relative !important;
    border: 0px !important;
    border-radius: 10px !important;
    width: 500px !important;
    max-width: 100% !important;
  }
  .addClientUserModel{
    inset: 0% !important;
    padding: 0px !important;
    overflow: hidden;
    position: relative !important;
    border: 0px !important;
    border-radius: 10px !important;
    width: 800px !important;
    max-width: 100% !important;
    background: #fff;
  }
  .modal_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #C00305;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    padding: 15px 23px;
  }
  .modalBody{
    padding: 20px;
  }
  .form-group{
    position: relative;
    padding-bottom: 20px;
  }
  .form-group label{
    color: #000;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
  }
  .form-group input{
    width: 100%;
    height: 40px;
    border: 1px solid #CCC;
    width: 100%;
    padding: 10px 15px;
    background: #fff;
    outline: none;
  }
  .form-group select{
    width: 100%;
    height: 40px;
    border: 1px solid #CCC;
    width: 100%;
    padding: 10px 15px;
    background: #fff;
    outline: none;
  }
  .form-group label sub{
    color: #C00305;
    vertical-align: top;
  }
  .form-group button{
    border: 0px;
    width: 45%;
  }
  .form-group button{
    margin-left: 0px;
  }
 .flxgrd{
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content:space-between;
 }
 .closebtn{
    background:transparent;
    color: #fff;
    cursor: pointer;
    border: 0px;
    padding: 0px !important;
    font-size: 20px ;
 }
 .userprofile{
    width: 250px;
    height: 250px;
    border-radius: 100%;
    border: 5px solid #ad140f;
    background: #ccc;
 }
 .userprofile img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
 }
 .btn-action{
    text-align: center;
    margin-top: 30px;
 }
 .btn-action a{
    display: inline-block;
    padding: 0px 10px;
 }
 .profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
 }
 .addowners .row .col-3{
     width: 20%;
 }
 .addowners .row .col-9{
    width: 80%;
}
.signupload{
    width: 250px;
    height: 125px;
    background: #D9D9D9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signupload span{
    color: #F5F5F5;
    font-size: 20px;
    font-weight: 400;
    display: inline-block;
}
.signupload{
    margin-top: 50px;
}
.btngrp{
    margin-top: 50px;
}
.btngrp button{
    border: 0px;
}
.partnerForm{
    border-left: 1px solid #ccc;
    padding-left: 20px;
}
.pdfbtn{
    width: 35px;
    height: 35px;
    border-radius: 8px;
    border: 1px solid #C00305;
    padding: 0px;
    background: #fff;
    margin-right: 10px;
}
.xlxbtn{
    width: 35px;
    height: 35px;
    border-radius: 8px;
    border: 1px solid #24BC0B;
    padding: 0px;
    background: #fff;
    margin-right: 10px;
}
.bdrbtn{
    height: 35px;
    border-radius: 8px;
    border: 1px solid #C00305;
    background: #fff;
    color: #C00305;
    padding: 0px 12px;
    margin-right: 10px;
    transition: 0.3s;
}
.rightSec button:last-child{
    margin-right: 0px;
}
.rightSec button.bdrbtn:hover{
    border: 1px solid #C00305;
    background: #C00305;
    color: #fff;
}
.viewInvoice{
    margin: 0px -20px;
}
.viewInvoice .pageTop{
    padding: 0px 20px;
    padding-bottom:20px;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 0px;
}
.viewInvoice .tavBox .react-tabs{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.viewInvoice .tavBox .react-tabs .tableft{
    width: 20%;
}
.viewInvoice .tavBox .react-tabs .tabright{
    width: 80%;
}
.tableft{
    border-right: 1px solid #D9D9D9; 
}
.tabRight{
    padding: 25px;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list{
    padding: 10px 15px;
    margin: 0px;
    list-style: none;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list h4{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
    font-size: 14px;
    color: #1031E1;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list p span{
    color: #888;
    font-size: 13px;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list h4 b{
    color: #C00305;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li{
    transition: 0.3s;
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 1px solid #D9D9D9;
    outline: none !important;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li:hover{
    background: #ad140f;
    border-bottom: 1px solid #ad140f;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li:hover h4{
    color: #fff;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li:hover h4 b{
    color: #fff;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li:hover p {
    color: #fff;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li:hover p span{
    color: #fff;
}
.react-tabs__tab--selected{
    background: #ad140f;
    border-bottom: 1px solid #ad140f;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li.react-tabs__tab--selected h4{
    color: #fff;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li.react-tabs__tab--selected h4 b{
    color: #fff;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li.react-tabs__tab--selected p {
    color: #fff;
}
.viewInvoice .tavBox .react-tabs .tableft .react-tabs__tab-list li.react-tabs__tab--selected p span{
    color: #fff;
}
.tabRight{
    width: calc(100% - 20%);
}
.tabdetails img{
    width: 100%;
}
.flxGroup{display: flex;
    flex-wrap: wrap;}
    .flxGroup select{
        width: calc(100% - 48px);  
    }
    .flxGroup button{
        width: 48px;
        background: #c00305;
        color: #fff;
        outline: none;
        transition: 0.3s;
    }
    .flxGroup button:hover{
        background: #af0508; 
    }
.addRow{
    text-align: right;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 10px;
    color: #c00305;
    cursor: pointer;

}
.addRowBlock{
    background: #fff !important;
}
.col-7{
    width: 60%;
    padding: 0px 15px;
}
.col-5{
    width: 40%;
    padding: 0px 15px;
}
.form-group textarea{
    width: 100%;
    height: 100px;
    border: 1px solid #CCC;
    padding: 10px 15px;
    background: #fff;
    outline: none;
    resize: none;
}
.addInvoice  .form-group .fileUpload{
    width: 100%;
    height: 100px;
    border: 1px dashed #d31332;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;
    cursor: pointer;
    background: #fff !important;
    margin: 0px !important;
    margin-bottom: 8px !important;
}
.form-group .fileUpload span img{
    margin-right: 8px;
}
.form-group small{
color: #ED0507;
font-size: 12px;
display: block;

}
.paymentsec h4{
    margin: 0px;
    color: #000;
    font-size: 14px;
    font-weight: normal;
}
.paymentoption{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    
}
.paymentoption label{
    display: inline-flex;
    align-items: center;
}
.paymentoption .checkbox{
    margin-right: 20px;
}
.addInvoice .btngrp button{
  margin-right: 10px;
  margin-left: 0px;
}
.subTotal{
    border: 1px solid #ccc;
    overflow: hidden;
    border-radius: 10px;
}
.subTotaltop{
    padding: 20px;
}
.subTotaltop h3{
    margin: 0px;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.customRedio{
    position: relative;
}
/* .customRedio input:checked + label::before{
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    left: 0px;
    background: #000;
} */
.customRedio input:not(checked) + label::before{
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    left: 0px;
    border-radius: 100%;
    border: 1px solid #ad140f;
}
.customRedio input:checked + label::after{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    left: 4px;
    border-radius: 100%;
    background: #ad140f;
    top: 4px;

}
.customRedio input:checked,
.customRedio input:not(:checked) {
    position: absolute;
    left: -9999px;
}
.customRedio label{
    padding-left: 25px;
    cursor: pointer;
}
.labelLeft{
    display: flex;
    align-items: center;
}
.labelLeft .customRedio{
    margin-right: 20px;
}
.priceItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}
.nagetive{
    color: #888;
}
.priceInp{
    display: flex;
    align-items: center;
}
.priceInp input{
    height: 30px;
    width: 150px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 10px;
    font-size: 13px;
    margin-right: 10px;
}
.total{
    display: flex;
    align-items: center;
    background: #d90b0d;
    padding: 15px 20px;
    justify-content: space-between;
}
.total h3{
    margin: 0px;
    color: #fff;
    font-size: 16px;
}
.total b{
    margin: 0px;
    color: #fff;
    font-size: 16px;
}
input[readOnly]{
    background: #ccc;
}
.payfull{
    position: relative;
}
.payfull a{
    right: 0px;
    top: 7px;
    padding: 0px 10px;
    position: absolute;
    white-space: nowrap;
    font-size: 12px;
    color: #d90b0d;
}
#addTable td small{
    font-size: 12px;
    color: #d90b0d;
    display: block;
}
.borderInline{
    display: inline-block;
    border: 1px solid #d90b0d;
    padding: 5px 10px;
    border-radius: 8px;
    margin-top: 10px;
}
.genarateTransction{
    background: #fff;
    width: 1000px !important;
    max-width: 90% !important;
}
.modalTop-left .form-group{
    margin-right: 15px;
    width: 180px;
}
.modalTop-left select{
    border-radius: 5px;
    border: 1px solid #CCC;
    background: #FFF;
    height: 35px;
    width: 100%;
    padding: 7px 15px;
    font-size: 14px;
    padding-left: 30px;
  
}
.modalTop-left .form-group img{
    position: absolute;
    top: 9px;
    left: 10px;
}
.modalTop-left{
    display: flex;
    align-items: center;
}
.modalTop-right button{
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: #fff;
    transition: 0.3s;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
}
.modalTop-right button:last-child{
    margin-right: 0px;
}
.modalTop{
    display: flex;
    justify-content: space-between;
}
.modalTop-right button.xls{
    border: 1px solid #24bc0b;
}
.modalTop-right button.xls:hover{
background: #08ca4942;
}
.modalTop-right button.pdf{
    border: 1px solid #d90b0d;
}
.modalTop-right button.pdf:hover{
    background: #d90b0e62;
}
.modalTop-right button.pinter{
    border: 1px solid #000;
}
.modalTop-right button.pinter:hover{
    background : #00000042;  
}

.notifymodal{
    background: #fff;
    width: 690px !important;
    max-width: 90% !important;
}
.modalHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    padding: 13px 24px;
    background: #C00305;
}
.modalHeader a{
    color: #FFF;
}
.modalBody .checkBoxGroup{
    justify-content: space-between;
}
.inlineForm{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px 0px;
}
.inlineForm label{
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    margin-right: 13px;
}
.inlineForm select{
    border-radius: 5px;
    border: 1px solid #CCC;
    background: #FFF;
    height: 40px;
    width: 350px;
    padding: 10px 15px;
}
.prvBox{
    border-radius: 10px;
    border: 1px solid #C00305;
    background: rgba(192, 3, 5, 0.05);
    padding: 15px;
    text-align: left;
}
.prvBox p{
    margin: 0px;
    font-size: 14px;
    line-height: 25px;
    text-align: left;

}
.preViewMsg label{
    color: #C00305;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    display: block;
    margin-bottom: 3px;
    text-align: left;

}
.preViewMsg{width: 90%;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;}
    .preViewMsg button{
        margin-top: 30px;
    }
    .uploadePic{
        width: 180px;
        height: 180px;
        overflow: hidden;
        border-radius: 100%;
        border: 5px solid #d90b0d;
    }
    .uploadePic img{width: 100%;
        height: 100%;
        object-fit: cover;}
        .photoUpload{
            display: flex;
            align-items: center;
        }
        .fileUpload{
            color: #FFF;
            font-size: 14px;
            font-weight: 500;
            display: inline-block;
            background: #d90b0d;
            position: relative;
            padding: 10px 15px;
            border-radius: 5px;
            margin-left: 20px;
            cursor: pointer;
        }
        .fileUpload input{
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            inset: 0px;
        
        }
        .verifyfld{
            position: absolute;
            background: #d90b0d;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            padding: 3px 6px;
            right: 10px;
            border-radius: 3px;
            top: 36px;
            display: inline-block;
        }
        .verifiedfld{
            position: absolute;
            background: #22e105;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            padding: 3px 6px;
            right: 10px;
            border-radius: 3px;
            top: 36px;
            display: inline-block;
        }
        .flxFld{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .flxFld button{
            width: 90px;
            height: 40px;
            background: #d90b0d;
            color: #fff;
            border-radius: 3px;
            outline: none;
            cursor: pointer;

        }
        .flxFld select{
            width: calc(100% - 100px);
        }
        .photoUpload{
            margin-bottom: 40px ;
        }
        .selefld{
            height: 40px;
            border: 1px solid #CCC;
            width: 100%;
            padding: 10px 15px;
            background: #fff;
            outline: none;
        }
        .form-group ul{
            margin: 0px;
            padding: 0px;
            list-style: none;
            border-radius: 0px 0px 10px 10px;
            background: #FFF;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
            position: absolute;
            z-index: 1;
            width: 100%;
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
        }
        .form-group ul li{
            padding: 5px 15px;
        }
        .form-group ul li input{
            height: auto;
        }
        .addjobbtn{
            text-align: center;
            background: #d90b0d;
            color: #fff;
            cursor: pointer;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .form-group ul.open{
            opacity: 1;
            pointer-events: all;
            visibility: visible;
        }
        .jobaddGroup{
            display: flex;
            align-items: center;
        }
        .jobaddGroup span{
            display: inline-block;
            font-size: 13px;
            border-radius: 5px;
            background: rgba(192, 3, 5, 0.10);
            padding: 4px 8px;
            margin-right: 8px;
            color: #C00305;
        }
        .jobaddGroup span i{
            margin-left: 5px;
            cursor: pointer;
        }
        .addServiceForm .btnsec{
            margin-top: 30px;
        }
        .addServiceForm .btnsec button{
            width: auto;
            margin-right: 10px;
        }
        .productsec h2{
            color: #C00305;
            font-size: 16px;
            font-weight: 500;
            margin: 0px;
            padding-bottom: 10px;
            border-bottom: 1px solid #D9D9D9;
            margin-bottom: 15px;
        }
        .copybtn{
            border-radius: 3px;
            border: 1px solid ;
            background: #FFF;
            color: #C00305;
            font-size: 12px;
            padding: 3px 7px;
            display: inline-block;
            cursor: copy;
            transition: 0.3s;
        }
        .copybtn:hover{
            background: #C00305;
            color: #fff;
        }
        .rightSec button.fadebtn{
           border: 0px;
        }

.linkdetails .react-tabs__tab p{
 color: #000 !important;
 font-weight: 600;
}
.linkdetails .react-tabs__tab:hover p{
    color: #fff !important;
}
.linkdetails .react-tabs__tab.react-tabs__tab--selected p{
    color: #fff !important;
}
.linkDetails span{
    color: #000;
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
}
.linkDetails h3{
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    margin-bottom: 20px;
}
.cardBorder{
    border-radius: 10px;
    border: 1px solid #C00305;
    background: #FFF;
    padding: 20px;
    position: relative;
    width: 512px;
    max-width: 100%;
    margin-bottom: 30px;
}
.cardBorder h4{
    margin: 0px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #333;
    font-weight: 500;
    margin-bottom: 15px;
}
.cardBorder h4 b{
color: #C00305;
font-size: 20px;
font-weight: 500;
display: inline-block;
}
.copylink{
    color: #C00305 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
.editlink{
    color: #888 !important;
    font-size: 14px !important;
    font-weight: 500 !important; 
}
.editlink i{
    color: #C00305 !important;
}
.cardBorder p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
}
.cardBorder p span{
    font-size: 14px;
}
.btnsec button{
    width: auto;
}
.btnsec button {
    margin-right: 10px;
}
.customRedio select{
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.picUpload{
    border-radius: 10px;
    border: 1px dashed #C00305;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 300px;
}
.picUpload span{
    text-align: center;
}
.picUpload  input{
    position: absolute;
    inset: 0px;
    height: 100%;
    border: 0px;
    opacity: 0;
}
.picUpload span small{
    display: block;
    margin-bottom: 10px;
}
.picUpload span small img{
    margin-right: 10px;
}
.picUpload span label{
    font-size: 16px;
    color: #000;
    font-weight: 500;
}
.expense label{
    color: #000;
font-size: 14px;
font-weight: 500;
display: block;
margin-bottom: 10px;
}
.expense h3{
 color: #C00305;
font-size: 30px;
font-weight: 500;
margin: 0px;
}
.documentHub button{
    border: 0px;
}
.documentItem{
     border: 1px solid #C00305;
     background: rgba(192, 3, 5, 0.10);
     display: flex;
     align-items: center;
     padding: 17px;
     border-radius: 10px;
     margin-bottom: 17px;
}
.documentItem i{
    font-size: 17px;
    color: #C00305;
    margin-right: 10px;
}
.documentItem label{
    font-weight: 500;
    color: #000;
    font-size: 15px;
}
.tableft h3{
    margin: 0px;
    margin-left: 15px;
    font-weight: 500;
    margin-top: 15px;
}
.openGroup .form-group{
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.openGroup .form-group select{
    width: calc(100% - 100px);
}
.openGroup .form-group button{
    width: 90px;
}
.notificationSetting .form-group{
    display: flex;
    align-items: center;
}
.notificationSetting .form-group label{
    white-space: nowrap;
    margin-right: 15px;
}
.todateformdate {
    border: 1px solid #CCC;
}
.todateformdate input{
    border: 0px solid ;
    height: 40px;
    width: 150px;
    background: #FFF;
    padding: 10px 15px;
    color: #000;
    outline: none;
    margin-right: 10px;
}
.accounthistory .right-action .xlxbtn{
    width: 40px;
    height: 40px;
    border-radius: 0px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.accounthistory .right-action .pdfbtn{
    width: 40px;
    height: 40px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notificationBox{
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;
}
.chk label small{
    display: inline-block;
}
.chk{
    display: inline-block;
    border: 1px solid #ad140f;
    padding: 10px;
    border-radius: 10px;
}
.chk label{
    margin-bottom: 0px;
}
.form-group p b{
    display: block;
}
.notificationImg{
    text-align: center;
    border-left: 1px solid #ccc;
}
#navigation.open ul li a{
    position: relative;
}
#navigation.open ul li a small{
    display: inline-block;
    width: 13px;
    height: 1px;
    background: #fff;
    position: absolute;
    right: 15px;
    top: 17px;
}

#navigation.open ul li a small b{width: 1px;
    height: 13px;
    display: inline-block;
    background: #fff;
    position: absolute;
    top: -6px;
    right: 6px;
}
#navigation.open ul li a:hover small{
    background: #C00305;
}
#navigation.open ul li a:hover small b{
    background: #C00305;
}
#navigation.open ul li a.active small{
    background: #C00305;
}
#navigation.open ul li a.active small b{
    background: #fff;
    display: none;
}

.form-group.flxotp{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.form-group.flxotp input{
    width: 50px !important;
}

.foleform .form-group textarea{
    height: 128px !important;
     }
     .accesstable h2{
         font-size: 30px;
         color: #000;
         margin: 0px;
         font-weight: 400;
         margin-bottom: 10px;
 
     }
     .accesstable h3{
         font-size: 20px;
         color: #000;
         margin: 0px;
         font-weight: 400;
         margin-bottom: 10px;
     }
     .flxth{
         display: flex;
         align-items: center;
     }
     .flxth .checkbox{
         margin-left:10px ;
     }

.activityLoader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffffc4;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination_div ul{
    margin: 0px;
    list-style: none;
    padding: 0px;
    display: inline-flex;
    border: 1px solid #ccc;
}
.pagination_div{
    text-align: right;
}
.pagination_div ul li{
    display: block;
    border-right: 1px solid #ccc;
}
.pagination_div ul li a{
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #333;
    transition: 0.3s;
}
.pagination_div ul li:last-child{
    border-right: 0px;
}
.pagination_div ul li.previous a{
    background: #fff;
    color: #C00305;
}
.pagination_div ul li.next a{
    background: #fff;
    color: #C00305;
}
.pagination_div ul li a:hover{
    background: #C00305;
    color: #fff;
}
.pagination_div ul li.selected a{
    background: #C00305;
    color: #fff;
}
.otp_input_parent_div{
    justify-content: center;
    display: flex;
}
.otp_input_parent_div input{
    width: 35px !important;
    height: 35px;
}
.account_button_tab{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0px;
    margin: 0px;
    list-style: none;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 15px;
}

.account_button_tab button{
    color: #C00305;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    padding: 8px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: rgba(192, 3, 5, 0.10);
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s;
    outline: none;
    border: none;
}

.account_button_tab button.active{
    background: #C00305;
    color: #fff;
}
.partner_image_upload{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    color: red;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.partner_image_upload input{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.signupload img{
    width: 100%;
    height: 100%;
    object-fit: contain;

}
.form-group-multiselect{
    width: 100%;
    min-height: 40px;
    margin-bottom: 20px;
}

.form-group-multiselect label sub {
    color: #C00305;
    vertical-align: top;
}


.multiSelectContainer .searchWrapper {
    min-height: 40px;
}

.form-group-permission-view ul{
    margin: 0;
    padding: 0;
}
.form-group-permission-view ul li{
    list-style: square;
}
.addStatory{
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    padding: 10px 15px;
    transition: 0.3s;
}
.fadebtn_resend{
    color: #C00305;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    background: rgba(192, 3, 5, 0.20);
    padding: 10px 15px;
    transition: 0.3s;
    position: relative;
}

button {
    border: 0;
}

.button {
    border: 0;
}
.accountstatutory input[readonly] {
    background: rgba(217, 217, 217, 0.50);
}
.hide_class{
    display: none;
}
.show_class{
    display: flex;
}